const USER_COMPLETE =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694443590/polaris/icons/user_complete.svg'
const USER_FAILED =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694545856/polaris/icons/user_failed.svg'
const USER_PENDING =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694443590/polaris/icons/user_pending.svg'

const LOGIN_STATUS_TO_LOGIN_STATUS_ICON: {
  [key: string]: string
} = {
  completed: USER_COMPLETE,
  delivered: USER_PENDING,
  processed: USER_PENDING,
}

export const getLoginStatusIcon = (loginStatus: string) => {
  const statusIcon = LOGIN_STATUS_TO_LOGIN_STATUS_ICON[loginStatus]
  if (statusIcon) return statusIcon
  return USER_FAILED
}
