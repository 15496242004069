import React from 'react'

import { Select } from 'frontend/components'

import { Label, Wrapper } from '../shared'

interface Props {
  selectedClient: any
}

const LABEL_TEXT = 'Client'

export const Client = ({ selectedClient }: Props) => {
  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select isDisabled value={selectedClient} />
    </Wrapper>
  )
}
