import React from 'react'

interface OncologyKdmToolPermissionsProps {
  changeIsCustomOncologyKdmToolScorer: (e: any) => void
  isCustomOncologyKdmToolScorer: boolean
  primaryClientTeamVegaId?: string | null
}

const OncologyKdmToolPermissions = ({
  changeIsCustomOncologyKdmToolScorer,
  isCustomOncologyKdmToolScorer,
  primaryClientTeamVegaId,
}: OncologyKdmToolPermissionsProps) => {
  return (
    <div>
      <input
        disabled={!Boolean(primaryClientTeamVegaId)}
        type={'checkbox'}
        checked={isCustomOncologyKdmToolScorer}
        onChange={changeIsCustomOncologyKdmToolScorer}
      />
      <label style={{ paddingLeft: 8 }}>
        Ability to customize sub-score weights
      </label>
    </div>
  )
}

export default OncologyKdmToolPermissions
