import React from 'react'

import { TextInput } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Last Name'
const FIELD_NAME = 'lastName'

export const LastName = ({ fieldState, onChange }: Props) => {
  const defaultValue = fieldState[FIELD_NAME]
  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <TextInput value={defaultValue} onChange={handleChange} />
    </Wrapper>
  )
}
