import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { processFile } from './processFile'

const Import = ({ name, onChange }) => {
  const [inputId, setInputId] = useState(undefined)
  useEffect(() => {
    setInputId(_.uniqueId())
  }, [])

  return (
    <div style={{ display: 'flex', margin: 12, alignItems: 'center' }}>
      <input
        id={inputId}
        style={{ display: 'block' }}
        type="file"
        onClick={(e) => {
          e.target.value = null
          onChange({ name, value: undefined })
        }}
        onChange={(e) => processFile(e, name, onChange)}
      />
    </div>
  )
}

export default Import
