import React from 'react'
import styled from '@emotion/styled'

import { ListPanel } from 'frontend/components'
import { useTeam } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { ALL_USERS_DATUM } from './constants'
import ListItem from './ListItem'

const SEARCH_STRING_KEY = 'team'
const SEARCH_STRING_ACCESSOR = 'id'

const Wrapper = styled.div({
  height: 'calc(100vh - 96px - 50px)',
})

const TeamsListPanel = () => {
  const { client: selectedClient }: { client?: string } = useQueryString()

  const { data: teams }: any = useTeam({
    queryInput: { client: selectedClient },
  })

  const teamsData = [ALL_USERS_DATUM].concat(teams)

  return (
    <Wrapper>
      <ListPanel
        data={teamsData}
        searchStringKey={SEARCH_STRING_KEY}
        searchStringAccessor={SEARCH_STRING_ACCESSOR}
        ListItem={ListItem}
      />
    </Wrapper>
  )
}

export default TeamsListPanel
