import React from 'react'
import { transparentize } from 'polished'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { GET_USER_TEAMS } from 'frontend/api/queries'

import { Colors } from 'frontend/utils/pulseStyles'

import UpdateButton from './UpdateButton'
import DeleteButton from './DeleteButton'

import getLoginStatusIcon from './getLoginStatusIcon'

const LoginStatusIcon = styled.img({
  width: '9px',
  height: '9px',
  marginRight: '6px',
})

const DEFAULT_WRAPPER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'default',
  color: Colors.BLACK,
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '-0.1px',
  lineHeight: '20px',
  padding: '8px 12px',
  margin: '0 12px',
  borderBottom: `1px solid ${transparentize(0.92, Colors.BLACK)}`,
  ':hover': {
    background: Colors.COOL_GRAY_200,
  },
}

const activePanelItemStyle = {
  cursor: 'default',
}

const teamBoxStyle = {
  margin: '0px 4px 0px 0px',
  background: transparentize(0.8, Colors.COOL_GRAY_500),
  borderRadius: 2,
  color: Colors.COOL_GRAY_500,
  fontSize: '10px',
  letterSpacing: '-0.1px',
  fontWeight: 500,
  lineHeight: '16px',
  padding: '2px 4px',
}

const lockedDefaultPathStyle = {
  ...teamBoxStyle,
  color: Colors.WHITE,
  background: transparentize(0.5, Colors.TOOL_SIDEBAR),
  padding: '2px 2px 2px 0',
}

const Wrapper = styled.div(DEFAULT_WRAPPER_STYLE, ({ style }) => ({
  ...style,
}))

const UserTeamsLabel = ({ userId }) => {
  const { data, loading, error } = useQuery(GET_USER_TEAMS, {
    variables: { userId },
  })

  if (loading) return 'Loading...'
  if (error) return 'Error!'

  const { teams } = data

  if (teams.length === 0)
    return (
      <span style={{ ...teamBoxStyle, color: Colors.RED }}>{'No Teams'}</span>
    )

  const teamNames = teams.map(({ _id, description }) => (
    <span key={_id} style={teamBoxStyle}>
      {description}
    </span>
  ))

  return teamNames
}

const getLabel1 = ({ username, defaultLanding }) => {
  return (
    <>
      <span>{username}</span>
      {!_.isEmpty(defaultLanding) && defaultLanding.locked && (
        <span style={lockedDefaultPathStyle}>PL</span>
      )}
    </>
  )
}

const getLabel2 = ({ _id }) => <UserTeamsLabel userId={_id} />

const UsersPanelListItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const loginStatusIcon = getLoginStatusIcon(data.loginStatus)
  const listItemHandleClick = isSelected
    ? () => null
    : () => handleClick(data[searchParamKey])

  const getButtonGroup = (user) => {
    if (user.isDefault) return null

    return (
      <div style={{ display: 'flex' }}>
        <UpdateButton userData={user} />
        <DeleteButton userId={user._id} handleClick={handleClick} />
      </div>
    )
  }

  const style = isSelected ? activePanelItemStyle : {}

  return (
    <Wrapper onClick={listItemHandleClick} style={style}>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LoginStatusIcon src={loginStatusIcon} />
          {getLabel1(data)}
        </div>
        <div style={{ fontWeight: 300, fontStyle: 'italic' }}>
          {getLabel2(data)}
        </div>
      </div>

      <div>{getButtonGroup(data)}</div>
    </Wrapper>
  )
}

UsersPanelListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  searchParamKey: PropTypes.string.isRequired,
}

export default UsersPanelListItem
