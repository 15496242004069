import { DataProxy } from '@apollo/client'
import React, { useState } from 'react'
import _ from 'lodash'

import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'
import { SlidingPanel } from 'frontend/components'
import { useClient, useTeam, useEmberUser } from 'frontend/entity-hooks/ember'

import { addUserToCache } from './utils'
import { UserForm } from './UserForm'

const HEADER_TEXT = 'Create User'

export const CreateUserSidePanel = () => {
  const { isLoading: areClientsLoading } = useClient()
  const {
    options: clientSourceOptions,
    areOptionsLoading,
  }: {
    create: any
    options: any
    areOptionsLoading: Boolean
  } = useTeam()
  const { create } = useEmberUser()

  const [fieldState, setFieldState] = useState({
    isManualPassword: false,
    teams: [],
    emailSubscriptions: [],
    isCustomOncologyKdmToolScorer: false,
  })

  const handleSubmit = () => {
    create({
      variables: { input: fieldState },
      update: (cache: DataProxy, data: any) => {
        const createdUser: any = Object.values(data.data)[0]
        const { client, teams } = createdUser
        const clientVariables = { input: { client: client.id } }
        addUserToCache(clientVariables, createdUser, cache)

        for (const { id: teamId } of teams) {
          const teamVariables = { input: { team: teamId } }
          addUserToCache(teamVariables, createdUser, cache)
        }
      },
    })
  }

  const handleFieldChange = ({ name, value }: any) => {
    _.set(fieldState, name, value)
    const newFieldState = {
      ...fieldState,
    }

    setFieldState(newFieldState)
  }

  const isLoading = areOptionsLoading || areClientsLoading
  const formattedClientSourceOptions =
    clientSourceOptions.client_source?.options ?? []

  return (
    <SlidingPanel
      header={HEADER_TEXT}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    >
      <UserForm
        fieldState={fieldState}
        clientSourceOptions={formattedClientSourceOptions}
        onChange={handleFieldChange}
        setFieldState={setFieldState}
      />
    </SlidingPanel>
  )
}
