import React, { useState } from 'react'
import styled from '@emotion/styled'

import { useRewriteSearchString } from 'frontend/hooks'

import { EditButton } from '../../../../shared/EditButton'
import {
  ACTIVE_ITEM_STYLE,
  Wrapper,
  ItemContainer,
  ContentContainer,
  Name,
  Description,
} from '../../styled-components'
import SitemapButton from './SitemapButton'

interface ItemProps {
  name: string
  description: string
}

interface ListItemProps {
  data: any
  isSelected: boolean
}

const ENTITY = 'team'
const QUERY_STRING_VALUE = 'editTeam'

const Buttons = styled.div({
  display: 'flex',
})

const ListItem = ({ data, isSelected }: ListItemProps) => {
  const [isHoveredListItem, setIsHoveredListItem] = useState(false)
  const rewriteSearchString = useRewriteSearchString()

  const rewriteClientParam = () => {
    if (!isSelected) {
      rewriteSearchString({
        team: data.id,
        user: undefined,
      })
    }
  }

  const wrapperStyle = isSelected ? ACTIVE_ITEM_STYLE : {}

  return (
    <Wrapper
      onClick={rewriteClientParam}
      onMouseEnter={() => setIsHoveredListItem(true)}
      onMouseLeave={() => setIsHoveredListItem(false)}
      style={wrapperStyle}
    >
      <Item name={data.name} description={data.description} />
      <Buttons>
        <SitemapButton isHoveredListItem={isHoveredListItem} teamId={data.id} />
        <EditButton
          isHoveredListItem={isHoveredListItem}
          isSelected={isSelected}
          datumId={data.id}
          entity={ENTITY}
          queryStringValue={QUERY_STRING_VALUE}
        />
      </Buttons>
    </Wrapper>
  )
}

export default ListItem

const Item = ({ name, description }: ItemProps) => {
  return (
    <ItemContainer>
      <ContentContainer>
        <Name>{name}</Name>
        <Description>{description}</Description>
      </ContentContainer>
    </ItemContainer>
  )
}
