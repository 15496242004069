import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { panelHeaderStyle, panelTitleStyle } from '../shared/panelStyles'

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 700,
  top: 0,
  width: '100%',
})

const Title = styled.div({
  color: Colors.BLACK,
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: '-0.2px',
  padding: 24,
})

const DASHBOARDS_TITLE = 'Dashboards '

const DashboardsPanelListHeader = ({ title }) => (
  <Header style={panelHeaderStyle}>
    <Title style={panelTitleStyle}>
      <span>{DASHBOARDS_TITLE}</span>
      <span style={{ color: Colors.COOL_GRAY_500 }}>({title})</span>
    </Title>
  </Header>
)

export default DashboardsPanelListHeader
