import { gql } from '@apollo/client'

export const GET_CLIENTS = gql`
  query GetClients {
    clients {
      _id
      name
      description
      icon
    }
  }
`
