import _ from 'lodash'
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@pulse-analytics/pulse-design-system'

import { UPDATE_CLIENT } from 'frontend/api/mutations'
import { GET_CLIENTS } from 'frontend/api/queries'
import { SingleActionDialog } from 'frontend/components/Dialog'
import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'
import { Form } from 'frontend/components'

const editIcon = (
  <FontAwesomeIcon size="lg" icon={faEdit} style={{ color: 'white' }} />
)

const buttonStyle = {
  border: 'none',
  background: 'none',
  color: '#0A2E4D',
  opacity: 0.3,
}

const FORM_CONFIG = [
  [
    {
      label: 'Name',
      accessor: 'description',
      inputType: INPUT_TYPES.TEXT,
    },
    {
      label: 'Icon',
      accessor: 'icon',
      inputType: INPUT_TYPES.TEXT,
    },
    {
      label: 'Regional Breakdowns',
      accessor: 'breakdowns',
      inputType: INPUT_TYPES.FILE,
      defaultValue: undefined,
    },
  ],
]

const UpdateModalButton = ({ client }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { __typename, name, ...initialFormState } = _.cloneDeep(client)
  const [formState, setFormState] = useState(initialFormState)

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
  } = useQuery(GET_CLIENTS)

  const [updateClient, { loading, error }] = useMutation(UPDATE_CLIENT, {
    update: (cache, { data: { updateClient } }) => {
      const newClientsData = clientsData.clients.filter(
        ({ _id }) => _id !== updateClient._id
      )
      let i = 0

      while (
        i < newClientsData.length &&
        newClientsData[i].description.toLowerCase() <
          updateClient.description.toLowerCase()
      ) {
        i++
      }
      newClientsData.splice(i, 0, updateClient)

      cache.writeQuery({
        query: GET_CLIENTS,
        data: { clients: newClientsData },
      })
    },
    onError: (e) => alert(e),
  })

  if (clientsLoading) return null
  if (clientsError) return <div>{clientsError}</div>

  const toggleModal = () => setIsOpen((prev) => !prev)
  const handleCancel = () => setIsOpen(false)
  const handleSubmit = () => {
    updateClient({ variables: { input: formState } })
  }
  const handleFormState = ({ name, value }) => {
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <Button style={buttonStyle} onClick={toggleModal}>
        {editIcon}
      </Button>
      {isOpen ? (
        <SingleActionDialog
          header={'Edit Client'}
          cancelHandler={handleCancel}
          submitHandler={handleSubmit}
          submitText={'submit'}
        >
          <Form
            datum={formState}
            formConfig={FORM_CONFIG}
            setFormState={handleFormState}
            style={{
              color: 'black',
              padding: '12px 12px 0 12px',
              borderBottom: 'none',
              cursor: 'default',
            }}
          />
        </SingleActionDialog>
      ) : null}
    </>
  )
}

export default UpdateModalButton
