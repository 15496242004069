import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { panelHeaderStyle, panelTitleStyle } from '../shared/panelStyles'

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 700,
  top: 0,
  width: '100%',
})

const Title = styled.div({
  color: Colors.BLACK,
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: '-0.2px',
  padding: 24,
})

const TOOLS_TITLE = 'Tools'

const ToolsPanelListHeader = () => (
  <Header style={panelHeaderStyle}>
    <Title style={panelTitleStyle}>{TOOLS_TITLE}</Title>
  </Header>
)

export default ToolsPanelListHeader
