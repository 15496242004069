import React from 'react'
import styled from '@emotion/styled'

import { getLoginStatusIcon } from './getLoginStatusIcon'
import { Teams } from './Teams'
import { Username } from './Username'

const LoginStatusUsernameWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const LoginStatusIcon = styled.img({
  width: 9,
  height: 9,
  marginRight: 6,
  marginBottom: 4,
})

const ContentWrapper = ({
  teams,
  username,
  loginStatus,
}: {
  teams: string[]
  username: string
  loginStatus: string
}) => {
  const loginStatusIcon = getLoginStatusIcon(loginStatus)

  return (
    <>
      <LoginStatusUsernameWrapper>
        <LoginStatusIcon src={loginStatusIcon} />
        <Username>{username}</Username>
      </LoginStatusUsernameWrapper>
      <Teams teams={teams} />
    </>
  )
}

export default ContentWrapper
