import React from 'react'
import styled from '@emotion/styled'

import { ActionBar } from './ActionBar'
import { FormContainer } from './FormContainer'
import { PanelHeader } from './PanelHeader'

interface Props {
  header: string
  formConfig?: any
  handleSubmit: Function
  setInputRef?: Function
  isLoading?: Boolean
  datum?: any
  childForm?: any
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const SidePanel = ({
  header,
  formConfig,
  handleSubmit,
  setInputRef,
  isLoading,
  datum,
  childForm,
}: Props) => {
  return (
    <Wrapper>
      <PanelHeader header={header} />
      <FormContainer
        formConfig={formConfig}
        setInputRef={setInputRef}
        isLoading={isLoading}
        datum={datum}
        childForm={childForm}
      />
      <ActionBar handleSubmit={handleSubmit} />
    </Wrapper>
  )
}

export default SidePanel
