import React from 'react'

import Header from './Header'
import { TitleSection } from './styled-components'

const TeamsPanelHeader = () => {
  return (
    <TitleSection>
      <Header />
    </TitleSection>
  )
}

export default TeamsPanelHeader
