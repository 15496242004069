import React, { useState } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import { useRewriteSearchString } from 'frontend/hooks'

import Item from './Item'
import { EditButton } from '../../shared/EditButton'

interface ListItemProps {
  data: any
  isSelected: boolean
}

const ENTITY = 'client'
const QUERY_STRING_VALUE = 'editClient'

const ACTIVE_ITEM_STYLE = {
  background: transparentize(0.9, Colors.BLUE),
  fontWeight: 600,
}

const Wrapper = styled.div({
  cursor: 'pointer',
  color: Colors.BLACK,
  borderRadius: 4,
  margin: `0px 8px`,
  padding: '4px 16px',
  height: 40,
  fontSize: 10,
  fontWeight: 450,
  lineHeight: '18px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    fontWeight: 600,
    background: transparentize(0.9, Colors.BLUE),
  },
})

const ListItem = ({ data, isSelected }: ListItemProps) => {
  const [isHoveredListItem, setIsHoveredListItem] = useState(false)
  const rewriteSearchString = useRewriteSearchString()
  const rewriteClientParam = () => {
    if (!isSelected) {
      rewriteSearchString({
        client: data.id,
        team: undefined,
        user: undefined,
      })
    }
  }

  const wrapperStyle = isSelected ? ACTIVE_ITEM_STYLE : {}

  return (
    <Wrapper
      onClick={rewriteClientParam}
      onMouseEnter={() => setIsHoveredListItem(true)}
      onMouseLeave={() => setIsHoveredListItem(false)}
      style={wrapperStyle}
    >
      <Item name={data.name} icon={data.icon} />
      <EditButton
        isHoveredListItem={isHoveredListItem}
        datumId={data.id}
        isSelected={isSelected}
        entity={ENTITY}
        queryStringValue={QUERY_STRING_VALUE}
      />
    </Wrapper>
  )
}

export default ListItem
