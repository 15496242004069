import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

const BORDER = `1px solid ${transparentize(0.9, Colors.BLACK)}`

export const Wrapper = styled.div({
  display: 'flex',
  cursor: 'pointer',
  color: Colors.BLACK,
  borderRadius: 4,
  margin: '0px 12px',
  padding: '12px 16px',
  fontSize: 10,
  fontWeight: 450,
  lineHeight: '18px',
  justifyContent: 'space-between',
  borderBottom: BORDER,
  ':hover': {
    fontWeight: 600,
    background: transparentize(0.9, Colors.BLUE),
  },
})
