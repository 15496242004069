import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  header: String
}

const Wrapper = styled.div({
  marginTop: 8,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
})

const Header = styled.div({
  fontSize: 20,
  fontWeight: 600,
  padding: 24,
})

export const PanelHeader = ({ header }: Props) => {
  return (
    <Wrapper>
      <Header>{header}</Header>
    </Wrapper>
  )
}
