import { transparentize } from 'polished'

import { Colors, Spacing } from '../../../utils/pulseStyles'

// Panel Container Style
export const defaultPanelStyle = {
  borderRight: `2px solid ${Colors.COOL_GRAY_200}`,
  minHeight: 'auto',
  maxHeight: 'auto',
  height: 'auto',
}

// Inactive Row Style
export const panelItemStyle = {
  alignItems: 'center',
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderRadius: 8,
  color: Colors.BLACK,
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '-0.1px',
  lineHeight: '20px',
  justifyContent: 'space-between',
  padding: '4px 12px',
  margin: '4px 12px',
  ':hover': {
    backgroundColor: Colors.COOL_GRAY_100,
  },
}

// Active (Selected) Row Style
export const panelItemActiveStyle = {
  backgroundColor: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
  fontWeight: 500,
  transition: 'all 400ms ease',
}

// Panel Header Container Style
export const panelHeaderStyle = {
  // height: 60,
  background: Colors.WHITE,
  borderBottom: `1px solid ${Colors.COOL_GRAY_200}`,
}

// Panel Title Text Style
export const panelTitleStyle = {
  color: Colors.BLACK,
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: '-0.2px',
  padding: 24,
}
