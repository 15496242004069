import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import _ from 'lodash'

import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'
import { Colors } from 'frontend/utils/pulseStyles'

const Wrapper = styled.div({
  flex: 0,
  // backgroundColor: '#EDF1F5',
  padding: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `2px solid ${Colors.COOL_GRAY_200}`,
})

const pageSuperheaderStyle = {
  fontWeight: 500,
  color: Colors.COOL_GRAY_400,
  fontSize: 12,
  letterSpacing: '-0.1px',
  marginBottom: 12,
}

const headerStyle = {
  fontWeight: 500,
  fontSize: 20,
  letterSpacing: '-0.4px',
  marginBottom: 8,
  marginTop: 8,
}

const clientLabelStyle = {
  color: Colors.COOL_GRAY_400,
  fontWeight: 500,
  fontSize: 12,
  letterSpacing: '-0.1px',
  marginBottom: 8,
}

const Header = ({ teamId, clientName, teamName, stagedSitemap }) => {
  // strip __typename and _id from the top-level sitemap obj
  let strippedSitemap = {}
  if (stagedSitemap) {
    const { __typename, _id, ...rest } = stagedSitemap
    strippedSitemap = rest
  }

  // prepare the data for potential persistence
  const updatedSitemap = _.mapValues(strippedSitemap, (obj) => {
    // arrayify the object
    const nodesAsArray = Object.values(obj)

    // remove any nodes that have been checked off (false values)
    const nodesAsArrayTrimmed = _.compact(nodesAsArray)

    // this step is necessary because https://github.com/apollographql/react-apollo/issues/741
    const nodesWithTypenameRemoved = nodesAsArrayTrimmed.map(
      ({ __typename, ...rest }) => rest
    )

    return nodesWithTypenameRemoved
  })

  return (
    <Wrapper>
      <div>
        <span style={pageSuperheaderStyle}>EDIT TEAM PERMISSIONS</span>
        <div style={headerStyle}>
          <span>{teamName}</span>
        </div>
        <div style={{ fontWeight: 500, fontSize: 12, letterSpacing: '-0.1px' }}>
          <span style={clientLabelStyle}>CLIENT: </span>
          <span>{clientName}</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CancelButton />
        <SubmitButton teamId={teamId} updatedSitemap={updatedSitemap} />
      </div>
    </Wrapper>
  )
}

Header.propTypes = {
  teamId: PropTypes.string,
  clientName: PropTypes.string,
  teamName: PropTypes.string,
  stagedSitemap: PropTypes.object,
}

export default Header
