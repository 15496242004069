import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Colors } from 'frontend/utils/pulseStyles'

import CreateButton from './CreateButton'

const Header = styled.div({
  backgroundColor: Colors.COOL_GRAY_200,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 100, // ! should be sourced from centralized style-guide file in the future
  borderBottom: `1px solid ${Colors.COOL_GRAY_300}`,
})

const Title = styled.div({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: '-0.3px',
  // textTransform: 'uppercase',
})

const TeamsPanelListHeader = ({ title, handleClick }) => {
  const teamsTitle = `${title}`

  return (
    <Header>
      <div style={{ padding: 24 }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: 500,
            color: Colors.GRAY_500,
            marginBottom: 4,
          }}
        >
          {teamsTitle}
        </div>
        <Title>Teams</Title>
      </div>
      <div style={{ paddingRight: 24 }}>
        <CreateButton handleClick={handleClick} />
      </div>
    </Header>
  )
}

TeamsPanelListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default TeamsPanelListHeader
