import { gql } from '@apollo/client'

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      _id
      name
      description
      icon
    }
  }
`
