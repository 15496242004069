import React from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import { Colors } from 'frontend/utils/pulseStyles'

import BulkCreateUsersModalButton from './BulkCreateUsersModalButton'
import CreateButton from './CreateButton'

const Header = styled.div({
  backgroundColor: '#f7f9fa',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 100, // ! should be sourced from centralized style-guide file in the future
  borderBottom: `1px solid ${Colors.COOL_GRAY_300}`,
})

const Title = styled.div({
  color: Colors.BLACK,
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: '-0.3px',
})

const UsersPanelListHeader = ({ title, handleClick }) => {
  const location = useLocation()

  const { teamId: selectedTeamId } =
    (location.search && queryString.parse(location.search)) || {}

  const usersTitle = `${title}`
  const isTeamAllUsers = selectedTeamId === 'allUsers'

  return (
    <Header>
      <div style={{ padding: 24 }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: 500,
            color: Colors.GRAY_500,
            marginBottom: 4,
          }}
        >
          {usersTitle}
        </div>
        <Title>Users</Title>
      </div>
      <div style={{ paddingRight: 24 }}>
        {!isTeamAllUsers && (
          <>
            <BulkCreateUsersModalButton />
            <CreateButton handleClick={handleClick} />
          </>
        )}
      </div>
    </Header>
  )
}

UsersPanelListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default UsersPanelListHeader
