import React from 'react'

import { TextInput } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Email Address'
const FIELD_NAME = 'email'

export const EmailAddress = ({ onChange }: Props) => {
  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <TextInput onChange={handleChange} />
    </Wrapper>
  )
}
