import React from 'react'

import { useEmberUser } from 'frontend/entity-hooks/ember'
import { ListPanel } from 'frontend/components'

import { useQueryInput } from '../useQueryInput'
import ListItem from './ListItem'

const SEARCH_STRING_KEY = 'user'
const SEARCH_STRING_ACCESSOR = 'id'

const UsersListPanel = () => {
  const queryInput: any = useQueryInput()
  const { data: usersData } = useEmberUser({
    queryInput: queryInput,
  })

  return (
    <ListPanel
      data={usersData}
      searchStringKey={SEARCH_STRING_KEY}
      searchStringAccessor={SEARCH_STRING_ACCESSOR}
      ListItem={ListItem}
    />
  )
}

export default UsersListPanel
