import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { UseReadConfigInput } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'
import { getTeamConfigs } from './getTeamConfigs'

interface UseReadTeamConfigInput extends UseReadConfigInput {
  client?: string
}

interface UseTeamProps {
  deleteOptions?: any
  queryInput?: UseReadTeamConfigInput
}

export const useTeam = ({ deleteOptions, queryInput }: UseTeamProps = {}) => {
  const location = useLocation()
  const { client } =
    (location.search && queryString.parse(location.search)) || {}

  const createVariables = { input: { client } }

  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
    optionsConfig,
  } = getTeamConfigs(deleteOptions, queryInput, createVariables)

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: readData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const {
    data: optionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(optionsConfig.tag, {
    labelMap: true,
  })

  const data = readData ? Object.values(readData)[0] : []
  const options = optionsData ? Object.values(optionsData)[0] : []

  return {
    data,
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
    options,
    areOptionsLoading,
  }
}
