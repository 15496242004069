import React from 'react'
import styled from '@emotion/styled'

import ContentWrapper from './ContentWrapper'

interface ItemProps {
  username: string
  teams: string[]
  loginStatus: string
}

const ItemWrapper = styled.section({
  display: 'flex',
  flexDirection: 'column',
})

const Item = ({ username, teams, loginStatus }: ItemProps) => {
  return (
    <ItemWrapper>
      <ContentWrapper
        teams={teams}
        username={username}
        loginStatus={loginStatus}
      />
    </ItemWrapper>
  )
}

export default Item
