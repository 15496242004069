import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { NewPolicy } from './modals'

const QUERY_STRING_TO_POLICY_MODAL: any = {
  newPolicy: <NewPolicy />,
}

const ImportModal = () => {
  const {
    importModal: selectedImportModal,
  }: { importModal?: string } = useQueryString()

  if (!selectedImportModal) return null

  const importModal = QUERY_STRING_TO_POLICY_MODAL[selectedImportModal]

  return importModal
}

export default ImportModal
