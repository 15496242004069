import React from 'react'
import { useMutation } from '@apollo/client'

import { REMOVE_NODE_FROM_TEAMS_AND_SITEMAPS } from 'frontend/api/mutations'
import { DeleteDialog } from 'frontend/components'

const LABEL = 'Remove Node From Teams And Sitemaps'
const SEARCH_STRING_PARAMS = ['removeNodeFromTeamsAndSitemaps']

const RemoveNodeFromTeamsAndSitemapsDialog = ({ node }) => {
  const [
    removeNodeFromTeamsAndSitemaps,
    { loading: isDeleting },
  ] = useMutation(REMOVE_NODE_FROM_TEAMS_AND_SITEMAPS, { onError: alert })

  const handleDelete = (handleCancel) => {
    const { _id } = node
    const input = { _id }
    removeNodeFromTeamsAndSitemaps({ variables: { input } }).then(
      ({ data: { removeNodeFromTeamsAndSitemaps } }) => {
        handleCancel()
        const entityDescription = getEntityDescription(
          removeNodeFromTeamsAndSitemaps
        )
        const message = `${entityDescription} removed from teams and sitemaps.`
        alert(message)
      }
    )
  }

  const entityDescription = getEntityDescription(node)

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default RemoveNodeFromTeamsAndSitemapsDialog

const getEntityDescription = ({ name, type }) => {
  return `${name} (${type})`
}
