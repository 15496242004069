import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Button from 'frontend/components/Button'
import useFlatFileList from './hooks/useFlatFileList'
import ModalRightPanel from './ModalRightPanel'
import {
  FieldContainer,
  FieldLabel,
} from '../../Orion/ImportExportData/NewImport/styledImportComponents'
import { customSelectStyles } from '../../components/customSelectStyles'
import Dialog from '../../components/Dialog'
import Select from 'react-select'
import Input from 'frontend/components/Input'
import { trimConfigDataToModalData, trimModalDataToReqPayload } from './utils'
import { PayerFlatFileTreatmentPlans } from './utils.tsx'
import PropTypes from 'prop-types'
import { useAuth0 } from '../../../react-auth0-spa'
import Color from 'frontend/utils/color'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'
import useExportConfigurations from './hooks/useExportConfigurations'
import _ from 'lodash'
import Spinner from 'frontend/components/Spinner'
import { TOOLS } from './toolPrivileges'

const EDIT_CONFIGURATION_URL = '/api/edit-export-config/'
const SAVE_CONFIGURATION_URL = '/api/save-export-config'
const DELETE_CONFIGURATION_URL = '/api/save-export-config/delete'

const SAVING = 'Saving...'
const SAVE = 'Save'
const DELETE = 'Delete Forever'
const DELETING = 'Deleting...'
const SAVED = 'Saved'
const ERROR = 'Error'
const DELETED = 'Deleted'
const DELETE_WARNING_TEXT = 'THIS CAN NOT BE UNDONE'

const MISSING_TOOL_SELECTION = 'Missing Tool Selection'
const MISSING_FIELD_TEAM_NAME = 'Missing Fields Team Name'
const MISSING_FIELD_CLIENT = 'Missing Fields Client'

const LOADING_STATES_DELETE = {
  idle: DELETE,
  success: DELETED,
  error: ERROR,
  loading: DELETING,
}

const LOADING_STATES_SAVE = {
  idle: SAVE,
  loading: SAVING,
  error: ERROR,
  success: SAVED,
}

const OuterDiv = styled.div({
  top: 20,
  position: 'absolute',
  left: 18,
  right: 18,
  bottom: 0,
  backgroundColor: '#fff',
  flex: 1,
  height: 'auto',
  borderRadius: 5,
  overflow: 'hidden',
})

const DeleteModalMiddleSection = styled.div({
  width: '100%',
  backgroundColor: '#f8fcfc',
  textAlign: 'center',
  padding: Spacing.HUGE,
  borderTopWidth: '1px',
  borderTopColor: Colors.MEDIUM_GRAY_2,
  borderBottomWidth: '1px',
  borderBottomColor: Colors.MEDIUM_GRAY_2,
  borderStyle: 'solid',
})

const Span = styled.span({
  marginTop: '10px',
  marginLeft: '10px',
  color: 'red',
  fontSize: '11px',
})

const SpinnerWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const INITIAL_MODAL_STATE = {
  client: null,
  teamName: null,
  tool: null,
  accounts: [],
  treatmentPlans: [],
}
const deleteDialogWrapperStyle = {
  width: '450px',
  margin: 'auto auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}

const ExportBusinessModal = ({ entityId, closeModal, setTableListData }) => {
  const { accessToken } = useAuth0()
  const [currentEntityId] = useState(entityId)
  const isEditModal = Boolean(currentEntityId)
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE)
  const [baseAccounts, setBaseAccounts] = useState([])
  const [baseTreatmentPlans, setBaseTreatmentPlans] = useState([])
  const [saveLoadingState, setSaveLoadingState] = useState(SAVE)
  const [deleteLoadingState, setDeleteLoadingState] = useState(DELETE)
  const [fieldsValidation, setFieldValidation] = useState({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { data: modalData, loading: isModalDataLoading } = useFlatFileList()
  const { data: configData, loading: isConfigLoaded } = useExportConfigurations(
    currentEntityId
  )
  const isProcessing = [isModalDataLoading, isConfigLoaded].some(
    (loading) => loading
  )

  const {
    clients,
    tools,
    phoenixTreatmentPlans,
    payerAccounts,
    pathwayAccounts,
    apmAccounts,
    providerAccounts,
    subTypes,
    booksOfBusiness,
    lineOfTherapies,
    payerFlatFileTreatmentPlans,
    mbmAccounts,
  } = modalData

  const apiHandler = async ({
    url,
    method,
    body,
    successCallBack = null,
    loadingFn,
    loadingStates,
  }) => {
    const loadingStateDelay = (close, timeOut) =>
      setTimeout(() => {
        loadingFn(loadingStates.idle)
        if (close) {
          closeModal()
        }
      }, timeOut)
    loadingFn(loadingStates.loading)

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (response.error) {
        loadingFn(loadingStates.error)
        loadingStateDelay(loadingFn, 2000)
      } else {
        loadingFn(loadingStates.success)
        successCallBack()
        loadingStateDelay(true, 1000)
      }
    } catch (error) {
      loadingFn(loadingStates.error)
    }
  }

  const deleteExportConfigurationById = () => {
    const fetchParams = {
      url: DELETE_CONFIGURATION_URL,
      method: 'POST',
      body: { _id: currentEntityId },
      successCallBack: setTableListData,
      loadingFn: setDeleteLoadingState,
      loadingStates: LOADING_STATES_DELETE,
    }
    apiHandler(fetchParams)
  }

  const saveConfiguration = () => {
    const isUpdate = isEditModal && currentEntityId
    const flatFileApiUrl = isUpdate
      ? EDIT_CONFIGURATION_URL + currentEntityId
      : SAVE_CONFIGURATION_URL
    const reqMethod = isUpdate ? 'PUT' : 'POST'
    const requestPayload = trimModalDataToReqPayload(modalState)
    const fetchParams = {
      url: flatFileApiUrl,
      method: reqMethod,
      body: requestPayload,
      successCallBack: setTableListData,
      loadingFn: setSaveLoadingState,
      loadingStates: LOADING_STATES_SAVE,
    }
    apiHandler(fetchParams)
  }

  const stateObjectValidator = () => {
    const isNotValidClient =
      !modalState.client || modalState.client.value === undefined
    const isNotValidTeam =
      !modalState.teamName || modalState.teamName.length === 0
    const isNotValidTool =
      !modalState.tool || modalState.tool.value === undefined

    setFieldValidation((prevState) => ({
      ...prevState,
      client: isNotValidClient && MISSING_FIELD_CLIENT,
      teamName: isNotValidTeam && MISSING_FIELD_TEAM_NAME,
      tool: isNotValidTool && MISSING_TOOL_SELECTION,
    }))

    const isAllFieldsValid = ![
      isNotValidClient,
      isNotValidTeam,
      isNotValidTool,
    ].some((notValidFields) => notValidFields)

    if (isAllFieldsValid) {
      saveConfiguration()
    }
  }

  const setBaseAccountList = (tool) => {
    let tmpAccountList

    switch (tool.toLowerCase()) {
      case TOOLS.PATHWAYS:
        tmpAccountList = pathwayAccounts
        break
      case TOOLS.PAYER:
        tmpAccountList = payerAccounts
        break
      case TOOLS.APM:
        tmpAccountList = apmAccounts
        break
      case TOOLS.PROVIDER:
        tmpAccountList = providerAccounts
        break
      case TOOLS.MBM:
        tmpAccountList = mbmAccounts
        break
      default:
        tmpAccountList = []
    }
    setBaseAccounts(tmpAccountList)
  }

  const setBaseTreatmentPlanList = (tool) => {
    let treatmentPlanList

    switch (tool.toLowerCase()) {
      case TOOLS.PAYER:
        const payerUtils = new PayerFlatFileTreatmentPlans()
        treatmentPlanList = payerUtils.revamp(payerFlatFileTreatmentPlans)
        break
      case TOOLS.PATHWAYS:
      case TOOLS.APM:
      case TOOLS.PROVIDER:
      case TOOLS.MBM:
        treatmentPlanList = phoenixTreatmentPlans
        break
      default:
        treatmentPlanList = []
    }
    setBaseTreatmentPlans(treatmentPlanList)
  }

  const setStagedAccounts = (accounts) => {
    setModalState((prevState) => ({ ...prevState, accounts }))
  }

  const setStagedTreatmentPlans = (treatmentPlans) => {
    setModalState((prevState) => ({ ...prevState, treatmentPlans }))
  }

  const resources = {
    baseTreatmentPlans,
    baseAccounts,
    subTypes,
    lineOfTherapies,
    booksOfBusiness,
    setStagedAccounts,
    setStagedTreatmentPlans,
  }

  const toolsOnChangeHandler = (tool) => {
    setModalState((prevState) => ({
      ...prevState,
      tool,
      accounts: [],
      treatmentPlans: [],
    }))
    setFieldValidation((prevState) => ({
      ...prevState,
      tool: null,
    }))
    setBaseAccountList(tool.label)
    setBaseTreatmentPlanList(tool.label)
  }

  const clientsOnChangeHandler = (client) => {
    setModalState((prevState) => ({
      ...prevState,
      client,
    }))
    setFieldValidation((prevState) => ({
      ...prevState,
      client: null,
    }))
  }

  const teamNameOnChangeHandler = (team) => {
    setModalState((prevState) => ({
      ...prevState,
      teamName: team.value,
    }))
    setFieldValidation((prevState) => ({
      ...prevState,
      teamName: null,
    }))
  }

  const deleteDialogHandler = () =>
    setIsDeleteDialogOpen((prevState) => !prevState)

  const loadingStatusColor = () => {
    let statusColor
    if (
      saveLoadingState === SAVE ||
      saveLoadingState === SAVING ||
      saveLoadingState === SAVED
    ) {
      statusColor = '#00c853'
    } else {
      statusColor = 'red'
    }
    return statusColor
  }

  useEffect(() => {
    const isValidConfigData =
      configData.length > 0 &&
      !_.isEmpty(configData[0]) &&
      !isProcessing &&
      isEditModal
    if (isValidConfigData) {
      const configDataParams = trimConfigDataToModalData(configData[0])
      setModalState((value) => ({
        ...value,
        ...configDataParams,
      }))
      setBaseAccountList(configDataParams.tool.label)
      setBaseTreatmentPlanList(configDataParams.tool.label)
    }
  }, [isProcessing, isEditModal])

  return (
    <div>
      <Dialog>
        <OuterDiv>
          {isDeleteDialogOpen && (
            <Dialog
              contentStyle={{ height: 'fit-content' }}
              contentWrapperStyle={deleteDialogWrapperStyle}
            >
              <div style={{ padding: Spacing.MEDIUM }}>
                <p
                  style={{
                    color: Color.RED,
                    fontWeight: 700,
                    fontSize: '17px',
                  }}
                >
                  Delete Export Configuration
                </p>
              </div>
              <DeleteModalMiddleSection>
                <p style={{ fontSize: '13px' }}>
                  Are you sure want to delete the{' '}
                  <b>
                    {modalState.client.label} {modalState.teamName}{' '}
                    {modalState.tool.label}
                  </b>{' '}
                  Export Configuration?
                </p>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    marginTop: Spacing.LARGE,
                  }}
                >
                  {DELETE_WARNING_TEXT}
                </p>
              </DeleteModalMiddleSection>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '15px',
                }}
              >
                <Button
                  type={'secondary'}
                  buttonStyle={{
                    marginRight: '15px',
                  }}
                  onClick={deleteDialogHandler}
                >
                  Cancel
                </Button>
                <Button
                  type={'primary'}
                  color="red"
                  onClick={deleteExportConfigurationById}
                >
                  {deleteLoadingState}
                </Button>
              </div>
            </Dialog>
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                padding: '15px 15px',
                borderBottom: '1px solid #dedede',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <b>Export Configuration</b>
              </div>
              <div>
                {isEditModal && (
                  <Button
                    type="secondary"
                    buttonStyle={{
                      marginRight: 20,
                      backgroundColor: 'pink',
                      color: 'red',
                    }}
                    onClick={deleteDialogHandler}
                  >
                    Delete Configuration
                  </Button>
                )}
                <Button
                  type="secondary"
                  buttonStyle={{
                    marginRight: 20,
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>

                <Button
                  onClick={stateObjectValidator}
                  color={loadingStatusColor()}
                  type="primary"
                >
                  {saveLoadingState}
                </Button>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
              }}
            >
              <div
                style={{
                  width: 350,
                  backgroundColor: '#f8fcfc',
                  padding: 20,
                }}
              >
                <h4
                  style={{
                    margin: '0px 0 10px 0',
                  }}
                >
                  {' '}
                  Details
                </h4>
                <FieldContainer>
                  <FieldLabel>Client (required)</FieldLabel>
                  <Select
                    onChange={clientsOnChangeHandler}
                    value={modalState.client}
                    options={clients?.map(({ name, _id }) => ({
                      label: name,
                      value: _id,
                    }))}
                    isLoading={isProcessing}
                    styles={customSelectStyles}
                  />
                  <Span>
                    {fieldsValidation.client ? fieldsValidation.client : null}
                  </Span>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Team Name (required)</FieldLabel>
                  <Input
                    name="team_name"
                    placeholder="Enter name for quick reference"
                    value={modalState.teamName}
                    type="text"
                    onChange={teamNameOnChangeHandler}
                  />
                  <Span>
                    {fieldsValidation.teamName
                      ? fieldsValidation.teamName
                      : null}
                  </Span>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>
                    Tool (unable to change after initial creation)
                  </FieldLabel>
                  <Select
                    isDisabled={isEditModal}
                    onChange={toolsOnChangeHandler}
                    value={modalState.tool}
                    options={tools?.map(({ name, _id }) => ({
                      label: name,
                      value: _id,
                    }))}
                    isLoading={isProcessing}
                    styles={customSelectStyles}
                  />
                  <Span>
                    {fieldsValidation.tool ? fieldsValidation.tool : null}
                  </Span>
                </FieldContainer>
              </div>
              {isProcessing && (
                <SpinnerWrapper>
                  <Spinner size={28} />
                </SpinnerWrapper>
              )}
              {modalState.tool?.value && !isProcessing && (
                <div
                  style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                >
                  <ModalRightPanel
                    resources={{ ...resources }}
                    state={{ ...modalState }}
                  />
                </div>
              )}
              {!modalState.tool?.value && !isProcessing && (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      color: '#96aec1',
                      textAlign: 'center',
                      fontSize: 12,
                    }}
                  >
                    <b>No Tool Selected</b>
                    <div>
                      Select a tool on the left to set Indications. Regimens and
                      Accounts for this export configuration
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </OuterDiv>
      </Dialog>
    </div>
  )
}

ExportBusinessModal.propTypes = {
  entityId: PropTypes.string,
  closeModal: PropTypes.func,
  setTableListData: PropTypes.func,
}

ExportBusinessModal.defaultProps = {
  closeModal: () => null,
  setTableListData: () => null,
  entityId: null,
}

export default ExportBusinessModal
