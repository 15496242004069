import React from 'react'

import { useQueryString } from 'frontend/hooks'

import Header from './Header'
import { HeaderSection } from '../styled-components'

const HeaderContainer = () => {
  const { client: selectedClient } = useQueryString()

  if (!selectedClient) return <HeaderSection>Teams</HeaderSection>

  return <Header />
}

export default HeaderContainer
