export const schemaV0 = `
  id
  firstName
  lastName
  username
  email
  loginStatus
  teams {
    id
    name
    default_landing_page
  }
  client {
    id
    name
  }
  primaryTeam
  defaultLanding
  emailSubscriptions {
    _id
    type
  }
  isCustomOncologyKdmToolScorer
  app_metadata {
    clientSources
    isCustomOncologyKdmToolScorer
  }
`
