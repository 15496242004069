import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { Colors } from 'frontend/utils/pulseStyles'

import SitemapButton from '../../shared/SitemapButton'
import UpdateButton from './UpdateButton'
import DeleteButton from './DeleteButton'

const DEFAULT_WRAPPER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  color: Colors.BLACK,
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '-0.1px',
  lineHeight: '20px',
  padding: '8px 16px',
  borderRadius: 8,
  margin: 8,
  ':hover': {
    backgroundColor: transparentize(0.5, Colors.COOL_GRAY_300),
  },
}

const activePanelItemStyle = {
  cursor: 'default',
  backgroundColor: Colors.BLUE_300,
  color: Colors.PRIMARY,
  fontWeight: 700,
  transition: 'all 400ms ease',
  ':hover': {
    backgroundColor: Colors.BLUE_300,
  },
}

const Wrapper = styled.div(DEFAULT_WRAPPER_STYLE, ({ style }) => ({
  ...style,
}))

const TeamsPanelListItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const listItemHandleClick = isSelected
    ? () => null
    : () => handleClick(data[searchParamKey])

  const getButtonGroup = (team) => {
    if (team._id === 'allUsers') return null
    if (team.isDefault) return <SitemapButton teamId={team._id} />

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UpdateButton
          team={team}
          handleClick={handleClick}
          searchParamKey={searchParamKey}
        />
        <SitemapButton teamId={team._id} />
        <DeleteButton
          teamId={team._id}
          handleClick={handleClick}
          searchParamKey={searchParamKey}
        />
      </div>
    )
  }

  const style = isSelected ? activePanelItemStyle : {}

  return (
    <Wrapper onClick={listItemHandleClick} style={style}>
      <div>
        <div>{data.description}</div>
      </div>

      <div>{getButtonGroup(data)}</div>
    </Wrapper>
  )
}

TeamsPanelListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  searchParamKey: PropTypes.string.isRequired,
}

export default TeamsPanelListItem
