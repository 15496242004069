import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { FontSpace } from 'frontend/utils'

export const Label = styled.label({
  color: Colors.BLACK,
  fontWeight: 700,
  ...FontSpace.FS2,
})
