import React from 'react'
import styled from '@emotion/styled'
import format from 'date-fns/format'

import { useProductionPermissions } from 'frontend/api'

const Wrapper = styled.div({
  paddingTop: '4px',
  fontSize: 11,
  fontStyle: 'italic',
  color: '#475566',
})

const DeployPermissionsDescription = () => {
  const { latestPusher, isLoadingLatestPusher } = useProductionPermissions()

  let lastDeployed = ''
  if(!isLoadingLatestPusher) {
    lastDeployed = `Last deployed by ${latestPusher.user.name}: ${format(new Date(latestPusher.timestamp), "M/d/yy, h:mm aaa")}`
  }

  return (
    <Wrapper>{lastDeployed}</Wrapper>
  )
}

export default DeployPermissionsDescription
