import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

export const Username = styled.div({
  fontWeight: 500,
  fontSize: 12,
  color: Colors.GRAY_500,
  marginBottom: 4,
})
