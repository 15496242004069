const readCacheWithoutError = (cache: any, queryObj: object) => {
  let data

  try {
    const tempData = cache.readQuery(queryObj)
    const dataKey = Object.keys(tempData)[0]
    data = tempData[dataKey]
  } catch (e) { }

  return data
}

export default readCacheWithoutError
