import React from 'react'

import { TextInput } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Username'
const FIELD_NAME = 'username'

export const Username = ({ onChange }: Props) => {
  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <TextInput onChange={handleChange} />
    </Wrapper>
  )
}
