import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

const FILE_NAME = 'Bulk Create Users'

const HEADERS = [
  [
    'First Name',
    'Last Name',
    'Username',
    'Email',
    'Client',
    'Team',
    'Client Source(s)',
    'Subscribe to Pathways Monthly Email Alert?',
    'Primary Team - Not Required',
  ],
  [
    'Please make sure no typo and extra spacing is included in the name.',
    'Please make sure no typo and extra spacing is included in the name.',
    'Please make sure no typo and extra spacing is included in the username.',
    'Please make sure no typo and extra spacing is included in the email.',
    '',
    '',
    '(MMIT, Dedham, Pulse)\n\nMMIT = MMIT client user\nDedham = Dedham client user\nPulse = any internal Dedham or MMIT test user',
    '\nYes / No',
    `Use "N/A" if there's no primary team`,
  ],
]

export const downloadExcelTemplate = async () => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(FILE_NAME)
  worksheet.properties.defaultColWidth = 30

  HEADERS.forEach((headers, rowIndex) => {
    const row = worksheet.getRow(rowIndex + 1)
    row.height = rowIndex === 0 ? 25 : 80
    headers.forEach((header, colIndex) => {
      const cell = worksheet.getCell(rowIndex + 1, colIndex + 1)
      cell.value = header
      cell.alignment = {
        vertical: 'top',
        wrapText: true,
      }
      cell.font = {
        bold: rowIndex === 0,
        color: { argb: 'FFFFFF' },
        size: 12,
      }
      cell.fill = {
        fgColor: { argb: '424242' },
        pattern: 'solid',
        type: 'pattern',
      }
    })
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(blob, `${FILE_NAME}.xlsx`)
}
