import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import Spacing from 'frontend/utils/spacing'

const phoenixLogo =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1573837414/polaris/icons/phoenix-1-color.svg'

const PhoenixHeader = styled.div({
  alignItems: 'center',
  backgroundColor: Colors.NAVY,
  color: Colors.PHOENIX,
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  padding: '16px 24px',
  textTransform: 'uppercase',
  maxWidth: 320,
  minWidth: 320,
})

const PhoenixLogo = styled.img({
  display: 'inline',
  marginRight: 16,
})

const ClientsPanelHeader = () => {
  return (
    <PhoenixHeader>
      <PhoenixLogo src={phoenixLogo} />
      <div>Client & User Management</div>
    </PhoenixHeader>
  )
}

export default ClientsPanelHeader
