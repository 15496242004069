import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import { usePulseToolsUser } from 'frontend/entity-hooks'

const BUTTON_STYLE = {
  padding: '3px 6px',
}

const Wrapper = styled.div({
  marginTop: 6,
})

const Text = styled.div({
  fontSize: 10,
  marginTop: 3,
})

const ResetPasswordButton = ({ user_id, isManualPassword }) => {
  const { resetPassword } = usePulseToolsUser()
  const isUserBeingCreated = !user_id
  if (isUserBeingCreated || isManualPassword) return null

  const input = { user_id }

  return (
    <Wrapper>
      <Button
        onClick={() => {
          resetPassword({ variables: { input } })
        }}
        style={BUTTON_STYLE}
      >
        Send Password Reset Email
      </Button>
      <Text>
        A message will be sent to the email associated with this account
      </Text>
    </Wrapper>
  )
}

export default ResetPasswordButton
