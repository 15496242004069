import React from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { lighten } from 'polished'

import { GET_TEAMS } from '../../../api/queries'

import { UPDATE_ROLE_SITEMAP } from '../../../api/mutations'

import Spinner from 'frontend/components/Spinner'

import { Colors, Spacing, FontFamily } from '../../../utils/pulseStyles'

const StyledButton = styled.button({
  backgroundColor: Colors.PRIMARY,
  border: 'none',
  borderRadius: 4,
  color: Colors.WHITE,
  cursor: 'pointer',
  fontSize: 12,
  fontWeight: 700,
  fontFamily: FontFamily.NORMAL,
  letterSpacing: '-0.1px',
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
  // textTransform: 'uppercase',
  ':hover': {
    backgroundColor: lighten(0.1, Colors.PRIMARY),
  },
})

const SubmitButton = ({ teamId, updatedSitemap }) => {
  const { clientId } = useParams()

  const [updateRoleSitemap, { loading, error }] = useMutation(
    UPDATE_ROLE_SITEMAP,
    {
      variables: { input: { updatedSitemap, teamId } },
      update: (cache) => {
        const { teams } = cache.readQuery({
          query: GET_TEAMS,
          variables: { clientId },
        })

        const clonedTeams = _.cloneDeep(teams)
        const teamIndex = clonedTeams.findIndex(({ _id }) => _id === teamId)
        const stagedTeam = _.cloneDeep(clonedTeams[teamIndex])
        stagedTeam.sitemap = updatedSitemap
        clonedTeams[teamIndex] = stagedTeam

        cache.writeQuery({
          query: GET_TEAMS,
          data: { teams: clonedTeams },
          variables: { clientId },
        })
      },
    }
  )

  if (loading)
    return (
      <div
        style={{
          diplay: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
        <span
          style={{
            color: Colors.PRIMARY,
            fontSize: 9,
            fontWeight: 700,
            paddingLeft: 12,
          }}
        >
          LOADING
        </span>
      </div>
    )
  if (error)
    return <div style={{ color: Colors.RED }}>Error processing request</div>

  return <StyledButton onClick={updateRoleSitemap}>Submit Changes</StyledButton>
}

SubmitButton.propTypes = {
  teamId: PropTypes.string,
  updatedSitemap: PropTypes.object,
}

export default SubmitButton
