import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { useClearSearchStringParam } from 'frontend/hooks'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: Colors.WHITE,
  boxShadow: '0px -3px 5px rgba(0, 0, 0, 0.05)',
  padding: `16px 24px`,
  position: 'absolute',
  bottom: 0,
  width: '100%',
})

const cancelButtonStyle = {
  padding: '8px 12px',
  marginRight: 24,
}

const saveButtonStyle = {
  padding: '8px 12px',
}

export const ActionBar = ({ handleSubmit }) => {
  const clearSearchStringParam = useClearSearchStringParam()

  const cancel = () => clearSearchStringParam(['sidePanel'])

  const submit = () => {
    handleSubmit()
    cancel()
  }
  return (
    <Wrapper>
      <Button
        type="secondary"
        onClick={cancel}
        color={Colors.GRAY_500}
        style={cancelButtonStyle}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={submit} style={saveButtonStyle}>
        Save
      </Button>
    </Wrapper>
  )
}
