import React from 'react'

import { Select } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  options: any
  onChange: any
}

const LABEL_TEXT = 'Client Source'
const FIELD_NAME = 'clientSources'

export const ClientSource = ({ options, onChange }: Props) => {
  const handleChange = ({ label }: any) => {
    onChange({
      name: FIELD_NAME,
      value: label,
    })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select options={options} onChange={handleChange} />
    </Wrapper>
  )
}
