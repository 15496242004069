import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div({
  marginTop: 6,
})

const Label = styled.label({
  paddingLeft: 8,
})

const Text = styled.div({
  fontSize: 10,
  marginTop: 3,
})

interface ManuallyCreatePasswordCheckboxProps {
  changeIsManualPassword: (e: any) => void
  isManualPassword: boolean
  userId?: string
}

const ManuallyCreatePasswordCheckbox = ({
  changeIsManualPassword,
  isManualPassword,
  userId,
}: ManuallyCreatePasswordCheckboxProps) => {
  return (
    <Wrapper>
      <input
        type={'checkbox'}
        checked={isManualPassword}
        onChange={changeIsManualPassword}
      />
      <Label>
        Manually Create Password
      </Label>
      <Subtitle isManualPassword={isManualPassword} userId={userId} />
    </Wrapper>
  )
}

export default ManuallyCreatePasswordCheckbox

interface SubtitleProps {
  userId?: string
  isManualPassword: boolean
}

const Subtitle = ({ userId, isManualPassword }: SubtitleProps) => {
  const isUserBeingUpdated = userId
  if (isManualPassword || isUserBeingUpdated) return null

  return (
    <Text>
      (by not adding a password, an automated email will be sent to the user requesting signup)
    </Text>
  )
}
