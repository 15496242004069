import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import iconPaths from './Svgs'

interface CustomSvgIconProps {
  iconName: keyof typeof iconPaths
  iconColor?: string
  styles?: React.CSSProperties
}

export const CustomSvgIcon = ({
  iconName,
  iconColor = 'black',
  styles,
}: CustomSvgIconProps) => {
  const Icon = iconPaths[iconName]

  if (!Icon) return null

  return (
    <SvgIcon style={{ ...styles }} viewBox="0 0 24 24">
      <Icon iconColor={iconColor} />
    </SvgIcon>
  )
}
