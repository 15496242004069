import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './Home'
import SitemapPanel from './SitemapPanel'
import FullOpLog from './Home/StatusPanel/OpLog/FullOpLog'

const Phoenix = () => {
  return (
    <div style={{ flex: 1 }}>
      <Switch>
        <Route exact path="/phoenix" component={Home} />
        <Route
          path="/phoenix/sitemap/:clientId/:teamId"
          component={SitemapPanel}
        />
        <Route path="/phoenix/oplog" component={FullOpLog} />
      </Switch>
    </div>
  )
}

export default Phoenix
