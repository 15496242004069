import React from 'react'

import Color from '../utils/color'
import { Colors } from 'frontend/utils/pulseStyles'
import styled from '@emotion/styled'

import Icon from '../components/Icon'

import { useAuth0 } from '../../react-auth0-spa'

import ToolCard from './ToolCard'
import { useFilterConfigFeatureFlags } from 'frontend/hooks'

const ICON_SIZE = 42

const TOOL_CARD_CONFIGS = [
  {
    title: 'Phoenix',
    description: 'User Management and Tool Permissions',
    to: '/phoenix',
    iconId: 'phoenix-1-white',
    iconColor: Color.PHOENIX,
  },
  {
    title: 'Orion',
    description: 'Import Sheets and Tool Data Management',
    to: '/orion',
    iconId: 'orion-1-white',
    iconColor: Color.ORION,
  },
  {
    title: 'Payer Project Access Import',
    description: 'Import Payer Historical Data',
    to: '/payer-projects',
    iconId: 'payer-1-white',
    iconColor: Color.PAYER_PROJECTS,
  },
  {
    title: 'Payer Policy Tracker',
    description: 'Track and Manage Payer Policy Links',
    to: '/payer-policy',
    iconId: 'payertracking-1-white',
    iconColor: Color.PAYER_PROJECTS,
  },
  {
    title: 'Payer Policy Tracker v1',
    description: 'Track and Manage Payer Policy Links',
    to: '/payer-policy-v1',
    iconId: 'payertracking-1-white',
    iconColor: Color.PAYER_PROJECTS,
    featureFlag: 'payerPolicyV1',
  },
  {
    title: 'Delphi',
    description: 'Email Alert Service',
    to: '/delphi',
    iconId: 'mercury-1-white',
    iconColor: Color.DELPHI,
  },
  {
    title: 'Flat File Export',
    description: 'Flat File Export',
    to: '/flat-file-export/list',
    iconId: 'flat-file-export',
    iconColor: Color.FLAT_FILE_EXPORT,
  },
  {
    title: 'Reports',
    description: 'Users and Client Tool Access',
    to: '/reports',
    iconId: 'report-color',
    iconColor: Color.REPORTS,
  },
]

const ToolCardLayoutContainer = styled.div`
  width: 25%;
  padding: 12px;

  @media (max-width: 1300px) {
    width: calc(100% / 3);
  }
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 732px) {
    width: 100%;
`

const Home = () => {
  const { loading, user } = useAuth0()
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()
  const filteredToolCardItems = filterConfigFeatureFlags(TOOL_CARD_CONFIGS)

  const toolCards = filteredToolCardItems.map((config: any) => (
    <ToolCardLayoutContainer key={config.title}>
      <ToolCard {...config} />
    </ToolCardLayoutContainer>
  ))

  if (loading) return null

  return (
    <div style={{ background: Colors.COOL_GRAY_200, width: '100%' }}>
      <div style={{ padding: '48px' }}>
        <div style={{ marginBottom: 60, padding: '0 12px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              width={ICON_SIZE}
              height={ICON_SIZE}
              iconName="polaris-1-color"
            />
            <span
              style={{
                color: Color.PRIMARY,
                fontWeight: 700,
                fontSize: 32,
                letterSpacing: '0px',
                lineHeight: 1,
                marginLeft: 12,
              }}
            >
              Polaris
            </span>
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '20px',
              color: Colors.COOL_GRAY_600,
              marginTop: 16,
            }}
          >
            Internal tool for managing client/user access and data across Pulse
            Analytics and Dedham Group. Need assistance, contact the Pulse
            Product Team.
          </div>
        </div>
        <div style={{ padding: 12 }}>
          <div
            style={{
              fontSize: 18,
              color: Color.BLACK,
              fontWeight: 400,
              marginBottom: 12,
            }}
          >
            Hello {user.name}
          </div>
          <div
            style={{
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '20px',
              color: Colors.COOL_GRAY_600,
              marginBottom: 32,
            }}
          >
            Select a module below or use the sidebar on the left to navigate
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
        >
          {toolCards}
        </div>
      </div>
    </div>
  )
}

export default Home
