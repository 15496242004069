import React from 'react'
import styled from '@emotion/styled'

import { PolarisDialog } from 'frontend/components'
import { Select } from 'frontend/components'

const MOCK_OPTIONS = [
  { label: 'Keytruda', value: 'keytruda' },
  { label: 'Test', value: 'test' },
]

const Wrapper = styled.div({
  width: 300,
})

export const NewPolicy = () => {
  return (
    <PolarisDialog>
      <Wrapper>
        <Select isMulti options={MOCK_OPTIONS} />
      </Wrapper>
    </PolarisDialog>
  )
}
