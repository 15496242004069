import React from 'react'
import { useQuery } from '@apollo/client'

import { GET_EMBER_USER_EMAILS } from 'frontend/api/queries'
import { DataTable, DataTableContainer } from 'frontend/components'
import { pathwaysEmailSubscription } from 'frontend/utils/email-subscription-options'

import { getColConfig } from './utils/getColConfig'
import { getExportConfig } from './utils/getExportConfig'

const subscriptionId = pathwaysEmailSubscription._id

const UsersTable = () => {
  const { data, loading: isLoading } = useQuery(GET_EMBER_USER_EMAILS, {
    fetchPolicy: 'network-only', // prevent caching to always get latest
  })

  const formattedData = formatData(data)

  const colConfig = getColConfig()
  const exportConfig = getExportConfig()

  const now = new Date()
  const excelLabel = `user-email-list-${now.toISOString()}`

  return (
    <DataTableContainer>
      <DataTable
        tableId={'delphi-pathways-users'}
        isLoading={isLoading}
        colConfig={colConfig}
        exportConfig={exportConfig}
        data={formattedData}
        excelLabel={excelLabel}
      />
    </DataTableContainer>
  )
}

export default UsersTable

const formatData = (data) => {
  const userData = data?.emberPathwaysSubscribedUsers ?? []

  return userData.map(({ client, firstName, lastName, username, email }) => ({
    client: client.name,
    firstName,
    lastName,
    username,
    email,
  }))
}
