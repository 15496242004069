import { CrudHookOptions } from '../../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'

import { getClientConfigs } from './getClientConfigs'

export const useClient = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {

  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getClientConfigs(deleteOptions, queryInput)

  const [create, { loading: isCreating, error: createError }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating, error: updateError }] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: clientData, loading: isLoading, error: queryError } = CRUD_UTILS.useRead(readConfig)
  const clientsData = clientData ? Object.values(clientData)[0] : []

  return {
    data: clientsData,
    queryError,
    create,
    isCreating,
    createError,
    update,
    updateError,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
  }
}
