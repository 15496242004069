import React from 'react'
import ReactSelect from 'react-select'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  isDisabled?: boolean
  isMulti?: boolean
  options?: any
  value?: any
  onChange?: any
  placeholder?: any
}

const defaultStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.COOL_GRAY_100,
    border: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: 13,
    color: Colors.GRAY_600,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: Colors.GRAY_600,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: Colors.BLACK,
    backgroundColor: transparentize(0.85, '#79818C'),
    borderRadius: 24,
    fontWeight: 600,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
}

export const Select = ({
  isDisabled,
  isMulti,
  options,
  value,
  onChange,
  placeholder = '',
}: Props) => {
  return (
    <ReactSelect
      isDisabled={isDisabled}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      styles={defaultStyles}
      placeholder={placeholder}
    />
  )
}
