import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'

import { useRewriteSearchString } from 'frontend/hooks'
import Spacing from 'frontend/utils/spacing'

import BulkCreateUsersModal from './BulkCreateUsersModal'

const BUTTON_STYLE = {
  lineHeight: 'normal',
  marginRight: '24px',
  padding: Spacing.S3,
}

const BulkCreateUsersModalButton = () => {
  const addQueryStringArg = useAddQueryStringArg()

  return (
    <>
      <BulkCreateUsersModal />
      <Button onClick={addQueryStringArg} style={BUTTON_STYLE}>
        Bulk Create Users
      </Button>
    </>
  )
}

export default BulkCreateUsersModalButton

const useAddQueryStringArg = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addQueryStringArg = () => rewriteSearchString({ bulkCreateUsers: true })

  return addQueryStringArg
}
