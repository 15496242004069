import React from 'react'

const SingleDocumentSvg = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          className="color1"
          id="document--text-content-a"
          d="M8.31428571,0 C8.85028571,0 9.368,0.182222222 9.77942857,0.515555556 L9.77942857,0.515555556 L15.5782857,5.22222222 C15.8377143,5.43333333 15.9885714,5.74555556 15.9885714,6.07555556 L15.9885714,6.07555556 L15.9885714,6.65444444 L10.8457143,6.65444444 C9.89828571,6.65444444 9.13142857,5.90888889 9.13142857,4.98777778 L9.13142857,4.98777778 L9.13142857,2 L1.99992753,2 L2,18 L14,18 L14,8.29928014 C14,8.14136314 14.1321239,8 14.2951069,8 L14.2951069,8 L15.7048931,8 C15.8687744,8 16,8.13581505 16,8.3033514 L16,8.3033514 L16,17.7777778 C16,19.0044444 14.9771429,20 13.7142857,20 L13.7142857,20 L2.28571429,20 C1.02285714,20 0,19.0044444 0,17.7777778 L0,17.7777778 L0,2.22222222 C0,0.994444444 1.02285714,0 2.28571429,0 L2.28571429,0 Z M11,14.5 C11.2761424,14.5 11.5,14.7238576 11.5,15 L11.5,15.5 C11.5,15.7761424 11.2761424,16 11,16 L5,16 C4.72385763,16 4.5,15.7761424 4.5,15.5 L4.5,15 C4.5,14.7238576 4.72385763,14.5 5,14.5 L11,14.5 Z M11,11.5 C11.2761424,11.5 11.5,11.7238576 11.5,12 L11.5,12.5 C11.5,12.7761424 11.2761424,13 11,13 L5,13 C4.72385763,13 4.5,12.7761424 4.5,12.5 L4.5,12 C4.5,11.7238576 4.72385763,11.5 5,11.5 L11,11.5 Z M11,8.5 C11.2761424,8.5 11.5,8.72385763 11.5,9 L11.5,9.5 C11.5,9.77614237 11.2761424,10 11,10 L5,10 C4.72385763,10 4.5,9.77614237 4.5,9.5 L4.5,9 C4.5,8.72385763 4.72385763,8.5 5,8.5 L11,8.5 Z"
        />
      </defs>
      <use
        fill={iconColor}
        fillRule="evenodd"
        transform="translate(4 2)"
        xlinkHref="#document--text-content-a"
      />
    </svg>
  )
}

export default SingleDocumentSvg
