import React, { useState } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import { DeleteButton } from '../../../../shared/DeleteButton'
import { EditButton } from '../../../../shared/EditButton'
import Item from './Item'
import { Wrapper } from './Wrapper'

const ACTIVE_ITEM_STYLE = {
  background: transparentize(0.9, Colors.BLUE),
  fontWeight: 600,
}

const ENTITY = 'user'
const QUERY_STRING_VALUE = 'editUser'

const Buttons = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ListItem = ({ data, isSelected }: { data: any; isSelected: boolean }) => {
  const [isHoveredListItem, setIsHoveredListItem] = useState(false)
  const rewriteSearchString = useRewriteSearchString()

  const rewriteClientParam = () => {
    if (!isSelected) {
      rewriteSearchString({
        user: data.id,
      })
    }
  }
  const wrapperStyle = isSelected ? ACTIVE_ITEM_STYLE : {}

  return (
    <Wrapper
      onClick={rewriteClientParam}
      onMouseEnter={() => setIsHoveredListItem(true)}
      onMouseLeave={() => setIsHoveredListItem(false)}
      style={wrapperStyle}
    >
      <Item
        username={data.username}
        teams={data.teams}
        loginStatus={data.loginStatus}
      />
      <Buttons>
        <EditButton
          isHoveredListItem={isHoveredListItem}
          isSelected={isSelected}
          datumId={data.id}
          entity={ENTITY}
          queryStringValue={QUERY_STRING_VALUE}
        />
        <DeleteButton
          isHoveredListItem={isHoveredListItem}
          isSelected={isSelected}
          datumId={data.id}
          entity={ENTITY}
        />
      </Buttons>
    </Wrapper>
  )
}

export default ListItem
