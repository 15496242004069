import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

interface ItemProps {
  name: string
  icon: string
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ImageContainer = styled.div({
  background: Colors.WHITE,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  marginRight: 16,
  '@media (max-width: 1000px)': {
    marginRight: 4,
  },
})

const Client = styled.span({
  fontSize: 12,
  '@media (max-width: 1000px)': {
    display: 'none',
  },
})

const Item = ({ name, icon }: ItemProps) => {
  return (
    <Wrapper>
      <ImageContainer>
        <img style={{ width: 20 }} src={icon} alt={`${name} icon`} />
      </ImageContainer>
      <Client>{name}</Client>
    </Wrapper>
  )
}

export default Item
