import _ from 'lodash'

const addPathToNodes = (nodes) => {
  const nodesById = _.keyBy(nodes, '_id')

  const memo = {}

  const injectedNodes = nodes.map((node) => {
    const injectedNode = _.cloneDeep(node)
    const path = getPathString(node, nodesById, memo) + ` (${node.type})`
    injectedNode.path = path
    return injectedNode
  })

  return injectedNodes
}

export default addPathToNodes

const getPathString = (node, nodesById, memo) => {
  let path = ''

  if (!node) return path

  if (memo[node._id]) return memo[node._id]

  const parentPath = getPathString(nodesById[node.parentId], nodesById, memo)

  path = parentPath + `/${node.name}`

  memo[node._id] = path

  return path
}
