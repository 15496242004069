import React from 'react'
import Select, { OptionTypeBase, OptionsType } from 'react-select'

interface ClientSourcesSelectProps {
  changeClientSources: any
  clientSources: string[]
}

const VALUE_TO_LABEL: { [key: string]: string } = {
  Pulse: 'Pulse',
  Dedham: 'Dedham',
  MMIT: 'MMIT',
}

const OPTIONS = Object.entries(VALUE_TO_LABEL).reduce(
  (acc: OptionTypeBase[], [value, label]: string[]) => {
    const option = { value, label }
    acc.push(option)
    return acc
  }, []
)

const ClientSourcesSelect = ({
  changeClientSources,
  clientSources,
}: ClientSourcesSelectProps) => {
  let value: OptionsType<OptionTypeBase> | undefined

  if (clientSources) {
    value = clientSources.map(
      (value) => {
        const label = VALUE_TO_LABEL[value]
        return { value, label }
      }
    )
  }

  return (
    <Select
      isMulti
      onChange={changeClientSources}
      options={OPTIONS}
      value={value}
    />
  )
}

export default ClientSourcesSelect
