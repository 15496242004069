import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

export const BORDER = `1px solid ${transparentize(0.9, Colors.BLACK)}`

export const ACTIVE_ITEM_STYLE = {
  background: transparentize(0.9, Colors.BLUE),
  fontWeight: 600,
}

export const Wrapper = styled.div({
  cursor: 'pointer',
  color: Colors.BLACK,
  borderRadius: 4,
  padding: '8px 16px',
  fontSize: 10,
  fontWeight: 450,
  lineHeight: '18px',
  margin: '12px 12px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: BORDER,
  ':hover': {
    fontWeight: 600,
    background: transparentize(0.9, Colors.BLUE),
  },
})

export const ItemContainer = styled.section({
  display: 'flex',
  flexDirection: 'column',
})

export const Name = styled.div({
  fontWeight: 500,
  fontSize: 12,
  color: Colors.BLACK,
})

export const Description = styled.div({
  fontWeight: 300,
  fontSize: 12,
  fontStyle: 'italic',
  color: Colors.GRAY_500,
})

export const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const TitleSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '12px 24px 13px',
  borderBottom: BORDER,
})

export const HeaderSection = styled.div({
  fontWeight: 600,
  fontSize: 20,
  color: Colors.BLACK,
})
