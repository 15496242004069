import React from 'react'

import { useClient } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { HeaderSection } from '../styled-components'

const Header = () => {
  const { client: selectedClient } = useQueryString()

  const {
    isLoading: clientsLoading,
    data: selectedClientData,
  }: any = useClient({
    queryInput: { id: selectedClient },
  })

  let text = 'Teams'
  if (!clientsLoading) text = `${selectedClientData[0].name} ${text}`

  return <HeaderSection>{text}</HeaderSection>
}

export default Header
