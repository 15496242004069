import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'

import { Colors } from 'frontend/utils/pulseStyles'
import Spacing from 'frontend/utils/spacing'

import UpdateModalButton from './UpdateModalButton'

const DEFAULT_WRAPPER_STYLE = {
  cursor: 'pointer',
  color: transparentize(0.4, Colors.WHITE),
  margin: `0 ${Spacing.S4}`,
  borderRadius: 4,
  padding: '8px 16px',
  textDecoration: 'none',
  fontSize: 10,
  fontWeight: 500,
  letterSpacing: '-0.1px',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    background: transparentize(0.9, Colors.WHITE),
  },
}

const activePanelItemStyle = {
  color: Colors.WHITE,
  background: transparentize(0.9, Colors.WHITE),
  fontWeight: 700,
  transition: 'all 400ms ease',
}

const Wrapper = styled.div(DEFAULT_WRAPPER_STYLE, ({ style }) => ({
  ...style,
}))

const Title = ({ description, icon }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {icon ? (
      <div
        style={{
          background: Colors.WHITE,
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
          marginRight: Spacing.S5,
        }}
      >
        <img
          style={{ width: 20, height: 'auto' }}
          src={icon}
          alt={`#{description}-icon`}
        />
      </div>
    ) : null}
    <span style={{ fontSize: 12 }}>{description}</span>
  </div>
)

const ClientsPanelListItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const listItemHandleClick = isSelected
    ? () => null
    : () => handleClick(data[searchParamKey])

  const style = isSelected ? activePanelItemStyle : {}

  return (
    <Wrapper onClick={listItemHandleClick} style={style}>
      <div>
        <div>
          <Title description={data.description} icon={data.icon} />
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <UpdateModalButton
          client={data}
          handleClick={handleClick}
          searchParamKey={searchParamKey}
        />
        {/* <DeleteButton clientId={client._id} /> */}
      </div>
    </Wrapper>
  )
}

ClientsPanelListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  searchParamKey: PropTypes.string.isRequired,
}

export default ClientsPanelListItem
