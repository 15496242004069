import React from 'react'

import { Colors } from '../../../utils/pulseStyles'

const StatusHeaderIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24">
    <path fill={props.color} fillRule="evenodd" d="M11.9456347,1.50151379 C12.3206274,1.48089744 12.675926,1.6721327 12.8652967,1.99745826 L12.8652967,1.99745826 L23.8647638,20.9981026 C24.0447651,21.3074919 24.0447651,21.6890504 23.8666382,21.9984397 C23.6875777,22.3087455 23.3575842,22.5 22.9994632,22.5 L22.9994632,22.5 L1.00053282,22.5 C0.642411826,22.5 0.312415885,22.3087647 0.13335779,21.9984397 C-0.0447638436,21.6890504 -0.0447638436,21.3074919 0.135232633,20.9981026 L0.135232633,20.9981026 L11.1346978,1.99745826 C11.3043745,1.70589168 11.6090725,1.5193269 11.9456179,1.50151379 Z M11.9999913,17.499467 C11.4478101,17.499467 10.9996909,17.9476026 10.9996909,18.4998041 C10.9996909,18.7651286 11.1056272,19.0191969 11.2931331,19.2066881 C11.480639,19.3941793 11.7346765,19.5001412 11.9999913,19.5001412 C12.2653061,19.5001412 12.5193651,19.3942009 12.7068494,19.2066881 C12.8943338,19.0191753 13.0002917,18.7651286 13.0002917,18.4998041 C13.0002917,18.2344795 12.8943554,17.9804112 12.7068494,17.79292 C12.5193435,17.6054288 12.2653061,17.499467 11.9999913,17.499467 Z M11.9840532,9.00009679 C11.432808,9.00947135 10.9922006,9.46229668 10.9996885,10.0144981 L10.9996885,10.0144981 L10.9996885,14.6139366 L11.0066853,14.7465532 C11.0329438,14.965482 11.1312479,15.1716021 11.2874908,15.3302048 C11.4759255,15.5205209 11.7318661,15.6283379 11.9999889,15.6283379 C12.2681116,15.6283379 12.5240426,15.5205233 12.7124869,15.3302048 C12.8999833,15.1398888 13.0040332,14.8811332 13.0002893,14.6139366 L13.0002893,14.6139366 L13.0002893,10.0144981 L12.993137,9.88023048 C12.9663583,9.6588967 12.8662279,9.4519965 12.706847,9.29261378 C12.515602,9.10135932 12.2549839,8.99635273 11.9840532,9.00009679 Z" />
  </svg>

)

export default StatusHeaderIcon

StatusHeaderIcon.defaultProps = {
  size: 24,
  color: Colors.WHITE,
}
