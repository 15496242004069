import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import UsersListPanel from './UsersListPanelOuterContainer'
import UsersPanelHeader from './UsersPanelHeader'

const PanelWrapper = styled.div({
  backgroundColor: Colors.WHITE,
  borderRadius: 8,
  marginBottom: 24,
  paddingBottom: 12,
})

const Panel = () => {
  return (
    <PanelWrapper>
      <UsersPanelHeader />
      <UsersListPanel />
    </PanelWrapper>
  )
}

export default Panel
