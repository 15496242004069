import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

const Tag = styled.span({
  background: Colors.GRAY_100,
  color: Colors.GRAY_600,
  fontSize: '11px',
  fontWeight: 600,
  lineHeight: '16px',
  marginRight: 6,
  padding: '2px 8px',
})

const TeamTag = ({ data }: any) => <Tag>{data}</Tag>

export default TeamTag
