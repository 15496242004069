import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import Button from 'frontend/components/Button'
import { useEmberNode } from 'frontend/entity-hooks/ember'

import Color from '../../../../utils/color'
import { Colors, FontFamily } from 'frontend/utils/pulseStyles'

import Spinner from 'frontend/components/Spinner'

const StyledTd = styled.td({
  padding: '8px 12px',
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '-0.1px',
  verticalAlign: 'middle',
  fontFamily: FontFamily.NORMAL,
  borderTop: `1px solid ${transparentize(0.92, Color.BLUE)}`,
})

const EmberToolTimestamp = ({ toolId, toolName, prevTimestamp }) => {
  const isoShortString = prevTimestamp ? prevTimestamp.slice(0, 10) : ''

  const [timestamp, setTimestamp] = useState(isoShortString)

  const { update, isUpdating } = useEmberNode({ queryInput: { type: 'tool' } })

  const handleUpdate = () =>
    update({
      variables: {
        input: { type: 'tool', id: toolId, tdg_timestamp: timestamp },
      },
    })

  return (
    <tr>
      <StyledTd style={{ padding: '8px 12px 8px 24px' }}>{toolName}</StyledTd>
      <StyledTd>
        <input
          value={timestamp}
          type="date"
          onChange={(e) => setTimestamp(e.target.value)}
          style={{
            padding: 8,
            border: 'none',
            background: transparentize(0.85, Color.BLUE),
            color: Color.BLUE,
            fontWeight: 500,
            fontFamily: FontFamily.NORMAL,
            borderRadius: 4,
            fontSize: 12,
          }}
        />
      </StyledTd>
      <StyledTd>
        {isUpdating ? (
          <Spinner />
        ) : (
          <Button type="secondary" onClick={handleUpdate}>
            Update
          </Button>
        )}
      </StyledTd>
    </tr>
  )
}

EmberToolTimestamp.propTypes = {
  toolId: PropTypes.string,
  toolName: PropTypes.string,
  prevTimestamp: PropTypes.string,
}

EmberToolTimestamp.defaultProps = {
  toolId: 'Tool missing id field',
  toolName: 'Tool missing name field',
  prevTimestamp: '',
}

export default EmberToolTimestamp
