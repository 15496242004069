import gql from 'graphql-tag'

export const CREATE_BULK_USERS = gql`
  mutation CreateBulkUsers($input: CreateBulkUsersInput!) {
    createBulkUsers(input: $input) {
      _id
      users {
        _id
        firstName
        lastName
        username
        email
        client {
          _id
          name
          description
          icon
        }
        emailSubscriptions {
          _id
          type
        }
        defaultLanding {
          locked
          path
        }
        primaryClientTeamVegaId
        app_metadata {
          clientSources
          isCustomOncologyKdmToolScorer
        }
      }
    }
  }
`

export const EMBER_CREATE_BULK_USERS = gql`
  mutation EmberCreateBulkUsers($input: EmberCreateBulkUsersInput!) {
    emberCreateBulkUsers(input: $input) {
      id
      firstName
      lastName
      username
      email
      loginStatus
      teams {
        id
        name
        default_landing_page
      }
      client {
        id
        name
      }
      primaryTeam
      defaultLanding
      emailSubscriptions {
        _id
        type
      }
      isCustomOncologyKdmToolScorer
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      _id
      firstName
      lastName
      username
      email
      client {
        _id
        name
        description
        icon
      }
      emailSubscriptions {
        _id
        type
      }
      defaultLanding {
        locked
        path
      }
      primaryClientTeamVegaId
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      _id
      firstName
      lastName
      username
      email
      client {
        _id
        name
        description
        icon
      }
      emailSubscriptions {
        _id
        type
      }
      defaultLanding {
        locked
        path
      }
      primaryClientTeamVegaId
      loginStatus
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      _id
      firstName
      lastName
      username
      email
      client {
        _id
        name
        description
        icon
      }
      emailSubscriptions {
        _id
        type
      }
      defaultLanding {
        locked
        path
      }
      primaryClientTeamVegaId
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`

export const UPDATE_USER_PRIMARY_CLIENT_TEAM = gql`
  mutation UpdateUserPrimaryClientTeam(
    $input: UpdateUserPrimaryClientTeamInput!
  ) {
    updateUserPrimaryClientTeam(input: $input) {
      _id
      firstName
      lastName
      username
      email
      client {
        _id
        name
        description
        icon
      }
      emailSubscriptions {
        _id
        type
      }
      defaultLanding {
        locked
        path
      }
      primaryClientTeamVegaId
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`
