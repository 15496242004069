import React from 'react'

import { Select } from 'frontend/components'

import { Label, Wrapper } from '../shared'

interface Props {
  options: any
  onChange: any
  selectedPrimaryTeam: any
}

const NO_PRIMARY_TEAM_DATUM = {
  label: 'No Primary Team',
  value: null,
}
const LABEL_TEXT = 'Primary Team'
const PLACEHOLDER_TEXT = 'No Primary Team'
const FIELD_NAME = 'primaryTeam'

export const PrimaryTeam = ({
  options,
  onChange,
  selectedPrimaryTeam,
}: Props) => {
  const handleChange = ({ value }: any) => {
    if (!value)
      onChange({ name: 'isCustomOncologyKdmToolScorer', value: false })
    onChange({ name: FIELD_NAME, value })
  }

  const selectOptions = [NO_PRIMARY_TEAM_DATUM].concat(options)
  const selectValue = selectOptions.find(
    ({ value }: any) => value === selectedPrimaryTeam
  )

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={selectOptions}
        value={selectValue}
        onChange={handleChange}
        placeholder={PLACEHOLDER_TEXT}
      />
    </Wrapper>
  )
}
