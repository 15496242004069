import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { UseReadConfigInput } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'
import { getEmberUserConfigs } from './getEmberUserConfigs'

interface UseReadEmberUserConfigInput extends UseReadConfigInput {
  client?: string
  team?: string
  user?: string
}

interface UseEmberUserProps {
  deleteOptions?: any
  queryInput?: UseReadEmberUserConfigInput
}

export const useEmberUser = ({
  deleteOptions,
  queryInput,
}: UseEmberUserProps = {}) => {
  const location = useLocation()
  const { clientId: selectedClientId } =
    (location.search && queryString.parse(location.search)) || {}

  const createVariables = { input: { selectedClientId } }

  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getEmberUserConfigs(deleteOptions, queryInput, createVariables)

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: userData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const usersData = userData ? Object.values(userData)[0] : []

  return {
    data: usersData,
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
  }
}
