import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_TEAMS } from 'frontend/api/queries'

const NO_PRIMARY_TEAM_OPTION = {
  label: 'No Primary Team',
  value: null,
}

const PrimaryTeamSelect = ({
  primaryClientTeamVegaId,
  checkboxesMap,
  handleChange,
}) => {
  const [primaryTeam, setPrimaryTeam] = useState({})
  const [primaryTeamOptions, setPrimaryTeamOptions] = useState([
    NO_PRIMARY_TEAM_OPTION,
  ])

  const location = useLocation()

  const { clientId: selectedClientId } = queryString.parse(location.search)

  const { data, loading } = useQuery(GET_TEAMS, {
    variables: { clientId: selectedClientId },
  })

  /*
    useEffect for setting the value of the primary team select.
    useEffect depends on primary team options or value change.
  */
  useEffect(() => {
    let primaryTeam = primaryTeamOptions.find(
      ({ value }) => value === primaryClientTeamVegaId
    )
    primaryTeam = primaryTeam || NO_PRIMARY_TEAM_OPTION
    setPrimaryTeam(primaryTeam)
  }, [primaryTeamOptions, primaryClientTeamVegaId])

  /*
    useEffect for resetting user's primary team & setting the options of the primary team select.
    Resetting user's primary team occurs if user is removed from currently selected primary team.
    useEffect depends on query load, client change, or user's teams change.
  */
  useEffect(() => {
    resetPrimaryTeam(
      loading,
      primaryClientTeamVegaId,
      data,
      checkboxesMap,
      handleChange
    )

    const primaryTeamOptions = getPrimaryTeamOptions(
      loading,
      data,
      checkboxesMap
    )
    setPrimaryTeamOptions(primaryTeamOptions)
  }, [data, selectedClientId, checkboxesMap])

  return (
    <Select
      value={primaryTeam}
      options={primaryTeamOptions}
      onChange={handleChange}
      isDisabled={loading}
      placeholder="Select Primary Team.."
    />
  )
}

PrimaryTeamSelect.propTypes = {
  primaryClientTeamVegaId: PropTypes.string,
  checkboxesMap: PropTypes.object,
  handleChange: PropTypes.func,
}

export default PrimaryTeamSelect

function resetPrimaryTeam(
  loading,
  primaryClientTeamVegaId,
  data,
  checkboxesMap,
  handleChange
) {
  if (loading) return

  if (primaryClientTeamVegaId) {
    const { _id } = data.teams.find(
      ({ uuid }) => uuid === primaryClientTeamVegaId
    )
    if (checkboxesMap[_id]) return
  }

  handleChange(NO_PRIMARY_TEAM_OPTION)
}

function getPrimaryTeamOptions(loading, data, checkboxesMap) {
  let primaryTeamOptions = [NO_PRIMARY_TEAM_OPTION]

  if (!loading) {
    data.teams.forEach(({ _id, uuid, name }) => {
      if (checkboxesMap[_id]) {
        const option = {
          label: name,
          value: uuid,
        }
        primaryTeamOptions.push(option)
      }
    })
  }
  return primaryTeamOptions
}
