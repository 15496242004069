import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Route } from 'react-router-dom'

import ClientManagement from './ClientManagement'

const ClientManagementRoute = () => {
  const { shouldShowNewPhoenix } = useFlags()

  if (!shouldShowNewPhoenix) return null

  return <Route path="/client-management" component={ClientManagement} />
}

export default ClientManagementRoute
