import React from 'react'

import TeamTag from './TeamTag'

export const Teams = ({ teams }: { teams: string[] }) => {
  return (
    <div>
      {teams.map(({ name }: any) => {
        return <TeamTag data={name} />
      })}
    </div>
  )
}
