import { useMutation } from '@apollo/client'

import { RESET_USER_PASSWORD } from './gql-tags'

export const usePulseToolsUser = () => {
  const [
    resetPassword,
    { loading: isResettingPassword },
  ] = useMutation(RESET_USER_PASSWORD, {
    onCompleted: () => {
      alert(
        'An email has been sent to this user to reset their password. If the user fails to receive the email, you will be sent an email with a link to give to the user.'
      )
    },
    onError: () => { alert('Failed to reset password. Please try again.') },
  })

  return {
    resetPassword,
    isResettingPassword,
  }
}
