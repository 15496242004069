import React from 'react'

import { TextInput } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/Orion/Administrator/EMBER_NodeManagement/constants'

import { Label, Wrapper } from '../shared'

const ParentNode = () => {
  const queryString: any = useQueryString()
  const parentNodeType = NODE_TYPE_TO_PARENT_TYPE[queryString.nodeType]

  const { data }: any = useEmberNode({
    queryInput: {
      type: parentNodeType,
      id: queryString[parentNodeType],
    },
  })

  return (
    <Wrapper>
      <Label>
        Parent Node
      </Label>
      <TextInput
        isDisabled
        value={data[0].name}
      />
    </Wrapper>
  )
}

export default ParentNode
