import { gql } from '@apollo/client'

export const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      _id
      name
      description
      icon
    }
  }
`

